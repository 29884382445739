import React from 'react';

const DashboardReview = () => {
    return (
        <div>
            <h4>My review items</h4>
        </div>
    );
};

export default DashboardReview;