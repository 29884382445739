import React from 'react';

const Loading = () => {
    return (

        <div className='h-screen flex justify-center items-center'>
            <button className="btn loading">loading</button>
        </div>

    );
};

export default Loading;